import React from 'react';
import * as styles from './spinner.module.scss';

type SpinnerProps = {
  dark?: boolean;
  small?: boolean;
};

export default function Spinner({ dark = false, small = false }: SpinnerProps) {
  return (
    <div className={`${styles.ldsDualRing} ${small ? styles.small : ''} ${dark ? styles.dark : ''} spinner`}></div>
  );
}