import React from 'react';
import * as styles from './form-error.module.scss';

type FormErrorProps = {
  valid: boolean;
  message: string;
};

export function FormError({ valid, message }: FormErrorProps) {
  return (
    <>
      {!valid ?
        <p className={styles.errorMessage + ' error__message'}>{message}</p>
        : null}
    </>
  );
}