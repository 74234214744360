import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { checkboxRequiredValidator, createFormField, emailValidator, minLength, passwordMatchValidator, requiredValidator, yearValidator } from '@/src/models/form.model';
import { countries } from '@/src/models/countries.model';
import { TranslationService } from '../../../services/translation.service';
import { useForm } from '@/src/hooks/form.hook';

import { FormError } from '@/src/components/form-error/form-error';
import InputDescription from '@/src/components/input-description/input-description';
import { LargeButton, SmallButton } from '@/src/components/button/button';
import Spinner from '@/src/components/spinner/spinner';
import * as styles from './register.module.scss';
import { useApi } from '@/src/contexts/api.context';

const defaultDetailsForm = {
  username: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="username"
            name="username"
            placeholder={translation.translate('authentication.username')}
            type="text"
            autoComplete="username"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  email: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="email"
            name="email"
            placeholder={translation.translate('authentication.email')}
            type="text"
            autoComplete="email"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
      emailValidator()
    ]
  },
  birthYear: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="birthYear"
            name="birthYear"
            placeholder={translation.translate('authentication.birth_year')}
            type="number"
            autoComplete="bday-year"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      yearValidator()
    ]
  },
  gender: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <select
            key={key}
            name="gender"
            id="gender"
            autoComplete="sex"
            value={value ? value : ''}
            onChange={event => onInputChange({ target: event.target } as any)}>
            {[
              { key: '', name: translation.translate('authentication.choose_gender') },
              { key: 'male', name: translation.translate('gender.male') },
              { key: 'female', name: translation.translate('gender.female') },
              { key: 'other', name: translation.translate('gender.other') }
            ].map(obj => (
              <option key={obj.key} value={obj.key}>{obj.name}</option>
            ))}
          </select>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: []
  },
  country: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <select
            key={key}
            name="country"
            id="country"
            value={value ? value : ''}
            onChange={event => onInputChange({ target: event.target } as any)}>
            {countries.map(country => (
              <option key={Object.keys(country)[0]} value={Object.keys(country)[0]}>{Object.values(country)[0]}</option>
            ))}
          </select>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    defaultValue: 'AX',
    validationRules: []
  },
  password: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="password"
            name="password"
            placeholder={translation.translate('authentication.password')}
            type="password"
            autoComplete="new-password"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
      minLength(8),
    ]
  },
  repeatPassword: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="repeatPassword"
            name="repeatPassword"
            placeholder={translation.translate('authentication.repeat_password')}
            type="password"
            autoComplete="new-password"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
      passwordMatchValidator('password')
    ]
  },
  privacyPolicy: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="privacyPolicy"
            name="privacyPolicy"
            type="checkbox"
            checked={value ? true : false}
            onChange={onInputChange} />
          <label className="checkbox-label" htmlFor="privacyPolicy">
            <span className={styles.underline} dangerouslySetInnerHTML={{ __html: translation.translate('authentication.accept_privacy_policy') }}></span>
          </label>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      checkboxRequiredValidator()
    ]
  },
  activationCode: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="activationCode"
            name="activationCode"
            placeholder={translation.translate('authentication.activation_code')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
    ]
  },
};

export default function RegisterDetailsForm({ pageContext, nextStep, detailsForm, activationCodeValid, setActivationCodeStatus }) {
  detailsForm = detailsForm || defaultDetailsForm;
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageContext));
  const [loading] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [showCodeError, setShowCodeError] = useState(false);

  const { renderInput, isFormValid, touchForm, form } = useForm(translation, detailsForm);

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();
    setSubmitAttempted(true);
    return false;
  };
  const validateActivationCode = useCallback(async (code: string) => {
    let result = await apiService.checkActivationCode(code);
    setShowCodeError(!result);
    setActivationCodeStatus(result);
  }, []);

  useEffect(() => {
    if(submitAttempted) {
      const valid = isFormValid();
      validateActivationCode(form.activationCode.value).catch(console.error).then(() => {
        if(valid && activationCodeValid) {
          nextStep(form);
        };
        setSubmitAttempted(false);
      });
    };
  }, [submitAttempted, activationCodeValid]);

  return (
    <form className="form" onSubmit={submit}>
      <h1>{translation.translate('authentication.create_account')}</h1>

      <div className="form__group">
        <div className="form__field">
          {renderInput('username')}
          <InputDescription>{translation.translate('authentication.username_description')}</InputDescription>
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('email')}
          <InputDescription>{translation.translate('authentication.email_description')}</InputDescription>
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('gender')}
          <InputDescription>{translation.translate('authentication.statistics_description')}</InputDescription>
        </div>

        <div className="form__field">
          {renderInput('birthYear')}
          <InputDescription>{translation.translate('authentication.statistics_description')}</InputDescription>
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('country')}
          <InputDescription>{translation.translate('authentication.statistics_description')}</InputDescription>
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('password')}
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('repeatPassword')}
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('activationCode')}
          <InputDescription>{translation.translate('authentication.activation_code_description')}</InputDescription>
          {showCodeError ? <FormError valid={activationCodeValid} message={translation.translate('form.activation_code_invalid')}></FormError> : null}
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('privacyPolicy')}
        </div>
      </div>

      <div className="form__group form__group--submit">
        <LargeButton type="submit" loading={loading}>
          <span>{translation.translate('authentication.next_step')}</span>
        </LargeButton>
      </div>

      <div className="form__group auth-link">
        <Link to={`${translation.linkPrefix}/login`}>{translation.translate('authentication.back_to_login')}</Link>
      </div>
    </form>
  );
}