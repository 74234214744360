import React from 'react';
import { TranslationService } from '../services/translation.service';

type RenderInput = (handleChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void, translation: TranslationService, value: any, isValid: boolean, error: string, key: React.Key, enabled?: boolean, form?: any) => JSX.Element | JSX.Element[];

export interface Validator {
  name: string;
  message: (translation: TranslationService) => string;
  validate: (inputValue: any, formConfig: FormConfig) => boolean;
}

export interface FormField {
  renderInput: RenderInput,
  value: any;
  valid: boolean;
  errorMessage: string;
  touched: boolean;
  validationRules: Validator[];
  defaultValue: any;
}

export interface FormConfig {
  [field: string]: FormField;
}

export function createFormField(renderInput: RenderInput): FormField {
  return {
    renderInput: renderInput,
    value: '',
    valid: false,
    errorMessage: '',
    touched: false,
    validationRules: [],
    defaultValue: null,

  };
}

export function createValidator(name: string, message: (translation: TranslationService) => string, validate: (inputValue: any, formConfig: FormConfig) => boolean): Validator {
  return {
    name,
    message,
    validate
  };
}

export function requiredValidator() {
  return createValidator(
    'required',
    (translation: TranslationService) => translation.translate('form.required'),
    (value, _) => !!value
  );
}

export function conditionalMinLength(minLength) {
  return createValidator(
    'minLength',
    (translation: TranslationService) => translation.translate('form.min_length').replace('{length}', minLength),
    (value, _) => (value.length == 0) || (value && value.length >= minLength)
  );
}


export function minLength(minLength) {
  return createValidator(
    'minLength',
    (translation: TranslationService) => translation.translate('form.min_length').replace('{length}', minLength),
    (value, _) => value && value.length >= minLength
  );
}

export function checkboxRequiredValidator() {
  return createValidator(
    'checkboxRequired',
    (translation: TranslationService) => translation.translate('form.required'),
    (value, _) => {
      return !!value;
    }
  );
}

export function yearValidator() {
  return createValidator(
    'year',
    (translation: TranslationService) => translation.translate('form.year_invalid'),
    (value, _) => (value === '' || value === null || (!!value && value.toString().length === 4 && (/^\d+$/).test(value)))
  );
}

export function emailValidator() {
  return createValidator(
    'email',
    (translation: TranslationService) => translation.translate('form.email'),
    (value, _) => {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const validEmail = emailRegex.test(String(value).toLowerCase());
      return validEmail;
    }
  );
}

export function passwordMatchValidator(target: string) {
  return createValidator(
    'passwordMatch',
    (translation: TranslationService) => translation.translate('form.repeat_password'),
    (value, formConfig) => {
      return value === formConfig[target].value;
    }
  );
}