import React from 'react';
import * as styles from './input-description.module.scss';

type InputDescriptionProps = {
  children: JSX.Element[] | JSX.Element | string;
};

export default function InputDescription({ children }: InputDescriptionProps) {
  return (
    <>
      <div className={styles.description}>{children}</div>
    </>
  );
}