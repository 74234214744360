import React, { useEffect, useState } from 'react';
import { createFormField, requiredValidator } from '@/src/models/form.model';
import { TranslationService } from '../../../services/translation.service';
import { useForm } from '@/src/hooks/form.hook';

import { FormError } from '@/src/components/form-error/form-error';
import { LargeButton } from '@/src/components/button/button';
import Spinner from '@/src/components/spinner/spinner';
import * as styles from './register.module.scss';

export default function RegisterSecurityQuestionsForm({ pageContext, register, back, securityQuestionsForm, setSecurityQuestionsForm, questions, registering, formErrorMessage }) {
  const defaultSecurityQuestionsForm = {};

  for(let i = 1; i <= 3; i++) {
    defaultSecurityQuestionsForm['securityQuestion' + i] = {
      ...createFormField(
        (onInputChange, translation, value, isValid, error, key, enabled, formState) => {
          return <>
            <select
              key={key}
              name={'securityQuestion' + i}
              id={'securityQuestion' + i}
              value={value ? value : ''}
              onChange={event => onInputChange({ target: event.target } as any)}>
              {[
                { id: '', text: translation.translate('authentication.security_question') + ' ' + i },
                ...questions.filter(q => {
                  // Filter the available questions by looping all other questions to see if they
                  // have been selected already, in that case don't render them as an option
                  for(let j = 1; j <= 3; j++) {
                    if(i !== j) {
                      const selectedValue = formState['securityQuestion' + j].value;

                      if(formState && selectedValue && q.id === selectedValue) {
                        return false;
                      }
                    }
                  }

                  return true;
                })
              ].map(obj => (
                <option key={obj.id} value={obj.id}>{obj.text}</option>
              ))}
            </select>
            <FormError valid={isValid} message={error}></FormError>
          </>;
        }
      ),
      validationRules: [
        requiredValidator()
      ]
    };

    defaultSecurityQuestionsForm['securityAnswer' + i] = {
      ...createFormField(
        (onInputChange, translation, value, isValid, error, key) => {
          return <>
            <input
              key={key}
              id={'securityAnswer' + i}
              name={'securityAnswer' + i}
              placeholder={translation.translate('authentication.security_answer') + ' ' + i}
              type="text"
              value={value ? value : ''}
              onChange={onInputChange} />
            <FormError valid={isValid} message={error}></FormError>
          </>;
        }
      ),
      validationRules: [
        requiredValidator()
      ]
    };
  }

  securityQuestionsForm = securityQuestionsForm || defaultSecurityQuestionsForm;

  const [translation] = useState(new TranslationService(pageContext));
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const { renderInput, isFormValid, form } = useForm(translation, securityQuestionsForm);
  const [questionSelected, setQuestionSelected] = useState(null);

  const goBack = (event: React.MouseEvent) => {
    event.preventDefault();
    back(form);
  };

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitAttempted(true);
    return false;
  };


  useEffect(() => {
    if(submitAttempted) {
      if(isFormValid()) {
        register(form);
      }
      setSubmitAttempted(false);
    }
    setQuestionSelected(false);
  }, [submitAttempted, questionSelected]);

  return (
    <form className="form" onSubmit={submit} autoComplete="off">

      <h1>{translation.translate('authentication.security_questions')}</h1>

      <p className="auth__description">{translation.translate('authentication.security_questions_info')}</p>

      <p className="auth__description">{translation.translate('authentication.security_questions_info_extra')}</p>

      {
        [1, 2, 3].map(i => {
          return <div className={styles.questionGroup} key={i}>
            <div className="form__group">
              <div className="form__field">
                {renderInput('securityQuestion' + i, true)}
              </div>
            </div>

            <div className="form__group">
              <div className="form__field">
                {renderInput('securityAnswer' + i, true)}
              </div>
            </div>
          </div>;
        })
      }

      {formErrorMessage ?
        <div className="form__error-message" dangerouslySetInnerHTML={{ __html: formErrorMessage }}></div> : null}

      <div className="form__group form__group--submit">
        <LargeButton type="submit" loading={registering}>
          <span>{translation.translate('authentication.create_account')}</span>
        </LargeButton>
      </div>

      <div className="form__group auth-link">
        <a onClick={goBack} href="#">{translation.translate('authentication.back_to_first_step')}</a>
      </div>
    </form>
  );
}