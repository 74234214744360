import React, { useState } from 'react';
import { PageProps, navigate } from 'gatsby';
import { TranslationService } from '../../../services/translation.service';
import { TranslationProvider } from '../../../contexts/translation.context';
import { Helmet } from 'react-helmet';
import { FormConfig } from '@/src/models/form.model';
import { graphql } from 'gatsby';

import Header from '@/src/components/header/header';
import RegisterDetailsForm from './register-details-form';
import RegisterSecurityQuestionsForm from './register-security-questions-form';
import { useApi } from '@/src/contexts/api.context';


export default function RegisterPage(pageProps: PageProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageProps.pageContext));
  const [detailsForm, setDetailsForm] = useState(null);
  const [securityQuestionsForm, setSecurityQuestionsForm] = useState(null);
  const [isNextStep, setIsNextStep] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(null);
  const [activationCodeValid, setActivationCodeValid] = useState(false);

  const questions = (pageProps.data as any).allMongodbEviSecurityQuestions.edges.map(e => e.node);

  const back = (form: FormConfig) => {
    setActivationCodeValid(false);
    setSecurityQuestionsForm(form);
    setIsNextStep(false);
  };
  const setActivationCodeStatus = (status: boolean) => {
    setActivationCodeValid(status);
  };

  const nextStep = (form: FormConfig) => {
    setDetailsForm(form);
    setIsNextStep(true);
  };

  const register = async (securityQuestionsForm: FormConfig) => {
    setSecurityQuestionsForm(securityQuestionsForm);
    setFormErrorMessage(null);
    setRegistering(true);

    let result;

    try {
      result = await apiService.register({
        username: detailsForm.username.value,
        email: detailsForm.email.value,
        gender: detailsForm.gender.value,
        birthYear: parseInt(detailsForm.birthYear.value),
        country: detailsForm.country.value,
        password: detailsForm.password.value,
        securityQuestions: [1, 2, 3].map(i => {
          const questionId = securityQuestionsForm['securityQuestion' + i].value;

          return {
            questionId: questionId,
            question: questions.find(q => q.id === questionId).text,
            answer: securityQuestionsForm['securityAnswer' + i].value,
          };
        }),
        activationCode: detailsForm.activationCode.value
      });

      navigate(`${translation.appLinkPrefix}/login`, { replace: true });
    } catch(err) {
      result = err;
    }

    if(!result.success) {
      switch(result.message) {
        case 'invalid-password':
          setFormErrorMessage(translation.translate('authentication.invalid_password'));
          break;
        default:
          setFormErrorMessage(translation.translate('error_contact_us')
            .replace('{supportEmail}', '<a href="mailto:support@evidencediary.com">support@evidencediary.com</a>'));
          break;
      }
    } else {
      try {
        apiService.setToken(result.token);
        navigate(`${translation.linkPrefix}`);
      } catch(err) {
        setFormErrorMessage(translation.translate('error_contact_us')
          .replace('{supportEmail}', '<a href="mailto:support@evidencediary.com">support@evidencediary.com</a>'));
      }
    }

    setRegistering(false);
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('register.title')}</title>
        <meta name="description" content={translation.translate('register.description')} />
        <meta http-equiv="content-language" content={translation.lang} />
        <html className="unauthenticated"></html>
      </Helmet>

      <Header unauthenticated={true} pageContext={pageProps.pageContext}></Header>

      <div className="unauthenticated__wrapper">
        <div className="unauthenticated__inner unauthenticated__inner--l">
          {
            !isNextStep ?
              <RegisterDetailsForm {...pageProps}
                nextStep={nextStep}
                detailsForm={detailsForm}
                activationCodeValid={activationCodeValid}
                setActivationCodeStatus={setActivationCodeStatus} /> :
              <RegisterSecurityQuestionsForm {...pageProps}
                back={back}
                securityQuestionsForm={securityQuestionsForm}
                setSecurityQuestionsForm={setSecurityQuestionsForm}
                register={register} questions={questions}
                registering={registering}
                formErrorMessage={formErrorMessage} />
          }
        </div>
      </div>

      <div className="decoration-circle-wrapper">
        <div className="decoration-circle">
        </div>
      </div>

    </TranslationProvider>
  );
}

export const query = graphql`
  query RegisterPageQuery {
    allMongodbEviSecurityQuestions {
      edges {
        node {
          id
          text
        }
      }
    }
  } 
`;