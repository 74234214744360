import React from 'react';
import Spinner from '../spinner/spinner';
import * as styles from './button.module.scss';

type ButtonProps = {
  onClick?: (event) => any;
  type: 'submit' | 'button';
  children: JSX.Element[] | JSX.Element;
  loading?: boolean;
  disabled?: boolean;
  className?: any;
};

export function LargeButton({ children, className, type, onClick, disabled = false, loading = false }: ButtonProps) {
  return (
    <button className={`${styles.shared + (className ? ' ' + className : '')} ${styles.largeButton} ${disabled || loading ? 'disabled' : ''}`} type={type} disabled={disabled} onClick={disabled ? null : onClick}>
      {loading ? <Spinner></Spinner> : null}
      {children}
    </button>
  );
}

export function SmallButton({ children, className, type, onClick, disabled = false, loading = false }: ButtonProps) {
  return (
    <button className={`${styles.shared + (className ? ' ' + className : '')} ${styles.smallButton} ${disabled || loading ? 'disabled' : ''}`} type={type} disabled={disabled} onClick={disabled ? null : onClick}>
      {loading ? <Spinner small={true}></Spinner> : null}
      {children}
    </button>
  );
}

export function CircleButton({ children, className, type, onClick, disabled = false, loading = false }: ButtonProps) {
  return (
    <button className={`${styles.circleButton + (className ? ' ' + className : '')} ${disabled || loading ? 'disabled' : ''}`} type={type} disabled={disabled} onClick={disabled ? null : onClick}>
      {loading ? <Spinner small={true}></Spinner> : null}
      {children}
    </button>
  );
}

export function TextButton({ children, className, type, onClick, disabled = false, loading = false }: ButtonProps) {
  return (
    <button className={`${styles.textButton + (className ? ' ' + className : '')} ${disabled || loading ? 'disabled' : ''}`} type={type} disabled={disabled} onClick={disabled ? null : onClick}>
      {loading ? <Spinner small={true}></Spinner> : null}
      {children}
    </button>
  );
} 